import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import NewsDetails from './Components/NewsDetails/NewsDetails'
import Home from './Pages/Home/Home'
import LatestNews from './Pages/LatestNews/Index'
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/post' element={<NewsDetails />} />
        <Route path='/latestnews' element={<LatestNews />} />
      </Routes>
    </Router>
  )
}

export default App
