import React from 'react'
import { Link } from 'react-router-dom'
import { FaAngleDoubleRight, FaCircle } from 'react-icons/fa'
import Navigation from '../../Components/Navbar/Navigation'
import Image from '../../Images/hero.png'
import './style.css'
function Index() {
  return (
    <>
      <Navigation />
      <section className='blog-news'>
        <div className='blog-news-card'>
          <h2 className='blog-news-card-date'>Dec 20, 2021</h2>
          <div className='blog-news-card-body'>
            <h1>
              ASUU: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididunt
            </h1>
            <div className='blog-news-card-img'>
              <img src={Image} alt='' />
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore
              et dolore magna aliqua.
            </p>
            <div className='blog-news-card-more'>
              <div className='dobuleRight'>
                <Link to='/newsdet'>
                  <h3>Continue reading</h3>
                  <FaAngleDoubleRight />
                </Link>
              </div>
              <div className='blogCircle'>
                <FaCircle />
                <p>12 comments</p>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-news-card'>
          <h2 className='blog-news-card-date'>Dec 20, 2021</h2>
          <div className='blog-news-card-body'>
            <h1>
              ASUU: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididunt
            </h1>
            <div className='blog-news-card-img'>
              <img src={Image} alt='' />
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore
              et dolore magna aliqua.
            </p>
            <div className='blog-news-card-more'>
              <div className='dobuleRight'>
                <Link to='/newsdet'>
                  <h3>Continue reading</h3>
                  <FaAngleDoubleRight />
                </Link>
              </div>
              <div className='blogCircle'>
                <FaCircle />
                <p>12 comments</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Index
