import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../Images/logo.png'
import './Navigation.css'

function Navigation() {
  const [isActive, setActive] = useState('false')
  const handleToggle = () => {
    setActive(!isActive)
  }
  const Current = new Date().toDateString()
  const Currents = new Date()
  const Days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const Day = Days[Currents.getDay() - 1];
  return (
    <>
      <div>
        <div className='logo-header'>
          <img src={Logo} alt='' />
        </div>
        <div className='newsnav'>
          <div className='logo'>
            <div>
              <h2>{Day}</h2>
              <h4>{Current.slice(3)}</h4>
            </div>
          </div>
          <div
            onClick={handleToggle}
            className={isActive ? 'hamburger' : 'hamburger toggle'}
          >
            <div className='line1'></div>
            <div className='line2'></div>
            <div className='line3'></div>
          </div>
          <ul className={isActive ? 'nav-links' : 'nav-links open'}>
            <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
              <Link className='link' to='/'>
                Home
              </Link>
            </li>
            <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
              <Link className='link' to='/latestnews'>
                Latest News
              </Link>
            </li>
            <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
              <Link className='link' to='/'>
                Undergraduate
              </Link>
            </li>
            <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
              <Link className='link' to='/'>
                Postgraduate
              </Link>
            </li>
            <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
              <Link className='link' to='/'>
                Diploma
              </Link>
            </li>
            <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
              <Link className='link' to='/'>
                Scholarships
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Navigation
