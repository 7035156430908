import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'
import Navbar from '../Navbar/Navbar'
import './NewsDetails.css'
import Image from '../../Images/blog1.png'

function NewsDetails() {
  return (
    <div>
      <Navbar />
      <section className='story'>
        <div className='story-main'>
          <h1>Johnson Olamilekan Samuel emerges winner of UNILAG BITE 1.0</h1>
          <div className='story-main-img'>
            <img src="https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640988684/bite_nnvah9.jpg" alt='' />
          </div>
          <p>
            Three students of the Faculty of Science emerged winners of the maiden edition of the 
            University of Lagos Business, Innovation and Talents Expression; UNILAG BITE 1.0.
            An Education Support Mobile Application designed by a 24-year-old final year student of 
            the Department of Physics; Samuel Olalekan Johnson won him the star prize of two million 
            naira (N2,000,000), the second prize of one million, five hundred thousand naira (N1,500,000) 
            went the way of a 25-year-old fresh graduate of the Department of Cell Biology and Genetics, 
            Zainab Ayomide Alayande for her creativity in leather works which she tagged Zeinny Craft while 
            a 26 year old Masters student of the Department of Chemistry, Victoria Omosomi Itsuokor smiled 
            home with a prize of seven hundred and fifty thousand naira (N750,000) having clinched the second 
            runner up position with her Vickie’s Bags initiative.
            <br/><br/>
            NThe Entrepreneurship competition known as Business, Innovation and Talents Expression; BITE 1.0. 
            anchored by the Entrepreneurship and Skills Development Centre (ESDC) of the University of Lagos 
            (UNILAG) recorded a total of 329 Business applications.
            According to the ESDC Director who is also the Project Lead for the BITE 1.0, Professor Sunday 
            Adebisi, 247 applications where shortlisted from the initial pool, out of which 120 applications 
            scaled through the first level pitching while 61 applications made it after the second level pitching.
            All 61 shortlisted contestants converged at the FSS Lecture Theatre for the grand finale which held on
            Friday, November 5, 2021 out of which 10 finalists were announced and invited for their final pitch 
            before a panel of judges led by the Executive Director, Fajemirokun Foundation, Mrs. Olawunmi Fajemirokun.
          </p>
        </div>
        <div className='story-details'>
          <h2>By Waltersam</h2>
          <h2>Dec 20 2021 - 10:30 am</h2>
          {/* <h2>12 comments</h2> */}
        </div>
        {/* <div className='story-share'>
          <h2>Share this story</h2>
          <div className='story-share-button'>
            <Link to='/'>
              <button className='facebuk'>
                <FaFacebookF />
                <p>Facebook</p>
              </button>
            </Link>
            <Link to='/'>
              <button className='twiter'>
                <FaTwitter />
                <p>Twitter</p>
              </button>
            </Link>
            <Link to='/'>
              <button className='whatapp'>
                <IoLogoWhatsapp />
                <p>WhatsApp</p>
              </button>
            </Link>
          </div>
        </div>
        <div className='story-related'>
          <h2>Related news</h2>
          <div className='story-related-wrap'>
            <div className='story-related-card'>
              <div className='story-related-card-left'>
                <Link to='/'>
                  <h3>PZ Cussons 2020 Chemistry Challenge ( 7th Edition)</h3>
                </Link>
                <p>
                  This is to inform the general public, Schools Teachers,
                  Parents, and Guardians that the online registration of the PZ
                  Cussons 7th edition chemistry challenge....
                </p>
                <h4>Dec 08, 2020</h4>
              </div>
              <div className='story-related-card-right'>
                <Link to='/'>
                  <img src={Image} alt='' />
                </Link>
              </div>
            </div>
            <div className='story-related-card'>
              <div className='story-related-card-left'>
                <Link to='/'>
                  <h3>PZ Cussons 2020 Chemistry Challenge ( 7th Edition)</h3>
                </Link>
                <p>
                  This is to inform the general public, Schools Teachers,
                  Parents, and Guardians that the online registration of the PZ
                  Cussons 7th edition chemistry challenge....
                </p>
                <h4>Dec 08, 2020</h4>
              </div>
              <div className='story-related-card-right'>
                <Link to='/'>
                  <img src={Image} alt='' />
                </Link>
              </div>
            </div>
            <div className='story-related-card'>
              <div className='story-related-card-left'>
                <Link to='/'>
                  <h3>PZ Cussons 2020 Chemistry Challenge ( 7th Edition)</h3>
                </Link>
                <p>
                  This is to inform the general public, Schools Teachers,
                  Parents, and Guardians that the online registration of the PZ
                  Cussons 7th edition chemistry challenge....
                </p>
                <h4>Dec 08, 2020</h4>
              </div>
              <div className='story-related-card-right'>
                <Link to='/'>
                  <img src={Image} alt='' />
                </Link>
              </div>
            </div>
            <div className='story-related-card'>
              <div className='story-related-card-left'>
                <Link to='/'>
                  <h3>PZ Cussons 2020 Chemistry Challenge ( 7th Edition)</h3>
                </Link>
                <p>
                  This is to inform the general public, Schools Teachers,
                  Parents, and Guardians that the online registration of the PZ
                  Cussons 7th edition chemistry challenge....
                </p>
                <h4>Dec 08, 2020</h4>
              </div>
              <div className='story-related-card-right'>
                <Link to='/'>
                  <img src={Image} alt='' />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='story-comment'>
          <h2>Comments (8)</h2>
          <div className='story-comment-wrap'>
            <div className='story-comment-wrap-card'>
              <div className='story-comment-wrap-left'>
                <img src={Image} alt='' />
              </div>
              <div className='story-comment-wrap-right'>
                <h3>Seun Paul</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <h5>50 minutes ago</h5>
              </div>
            </div>
            <div className='story-comment-wrap-card'>
              <div className='story-comment-wrap-left'>
                <img src={Image} alt='' />
              </div>
              <div className='story-comment-wrap-right'>
                <h3>Seun Paul</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <h5>50 minutes ago</h5>
              </div>
            </div>
            <div className='story-comment-wrap-card'>
              <div className='story-comment-wrap-left'>
                <img src={Image} alt='' />
              </div>
              <div className='story-comment-wrap-right'>
                <h3>Seun Paul</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <h5>50 minutes ago</h5>
              </div>
            </div>
            <div className='story-comment-wrap-card'>
              <div className='story-comment-wrap-left'>
                <img src={Image} alt='' />
              </div>
              <div className='story-comment-wrap-right'>
                <h3>Seun Paul</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <h5>50 minutes ago</h5>
              </div>
            </div>
            <div className='story-comment-wrap-card'>
              <div className='story-comment-wrap-left'>
                <img src={Image} alt='' />
              </div>
              <div className='story-comment-wrap-right'>
                <h3>Seun Paul</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <h5>50 minutes ago</h5>
              </div>
            </div>
          </div>
        </div>
        <div className='story-post'>
          <h2>Post Comment</h2>
          <div className='story-post-form'>
            <form action=''>
              <div className='story-post-input'>
                <input type='text' placeholder='Name'/>
              </div>
              <div className='story-post-input'>
                <textarea name='' id='' cols='30' rows='10' placeholder='Message'></textarea>
              </div>
              <button>Submit Comment</button>
            </form>
          </div>
        </div> */}
      </section>
    </div>
  )
}

export default NewsDetails
