import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../Components/Navbar/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import './Home.css'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import User from '../../Images/user.png'
import Hero from '../../Images/hero.png'
import Icon from '../../Images/image 1.png'
import HomeData from '../../Components/Datafiles/Home'
import TrendingData from '../../Components/Datafiles/Trending'
import SchorlashipData from '../../Components/Datafiles/Schorlaship'

SwiperCore.use([Autoplay, Pagination, Navigation])

function Home() {
    const [category, setCategory] = useState('all')
  return (
    <>
      <Navbar />
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        className='hero-swiper'
      >
        <SwiperSlide>
          <div className='swiper-left'>
            <h1>Waltersam Services celebrates 3rd Year Anniversary with new website launch</h1>
            <div>
              <ul>
                <li className='swiper-level'>General</li>
                <Link to='/newsdet'>
                  {/* <li className='swiper-more'>Read more</li> */}
                </Link>
              </ul>
            </div>
            <div className='swiper-user'>
              <div>
                <img src="https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640905788/DSC_9852_keqkpg.jpg" alt='' />
              </div>
              <div>
                <h2>posted by Seun</h2>
                <p>31 - 12 - 2021</p>
              </div>
            </div>
          </div>
          <div className='swiper-right'>
            <img src="https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640988684/weblaunch_lildsh.jpg" alt='' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-left'>
            <h1>Johnson Olamilekan Samuel emerges winner of UNILAG BITE 1.0</h1>
            <div>
              <ul>
                <li className='swiper-level'>General</li>
                <Link to='/post'>
                  <li className='swiper-more'>Read more</li>
                </Link>
              </ul>
            </div>
            <div className='swiper-user'>
              <div>
                <img src="https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640905772/DSC_9897_a2efap.jpg" alt='' />
              </div>
              <div>
                <h2>Posted by Waltersam</h2>
                <p>20 - 12 - 2021</p>
              </div>
            </div>
          </div>
          <div className='swiper-right'>
            <img src="https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640988684/bite_nnvah9.jpg" alt='' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-left'>
            <h1>Happy New!!! From Everyone at waltersam services </h1>
            <div>
              <ul>
                <li className='swiper-level'>General</li>
                <Link to='/newsdet'>
                  {/* <li className='swiper-more'>Read more</li> */}
                </Link>
              </ul>
            </div>
            <div className='swiper-user'>
              <div>
                <img src="https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640905772/DSC_9897_a2efap.jpg" alt='' />
              </div>
              <div>
                <h2>Posted by Waltersam</h2>
                <p>01 - 01 - 2022</p>
              </div>
            </div>
          </div>
          <div className='swiper-right'>
            <img src="https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640990176/newyear_gvjwnm.jpg" alt='' />
          </div>
        </SwiperSlide>
      </Swiper>
      <section className='blog'>
        <div>
          <h1>More Updates Coming Soon</h1>
          <p>It's all about Joy and celebration</p>
        </div>
        {/* <div className='blog__left'>
          <div className='blog__left-wrapper'>
            <div className='blog__left-nav'>
              <ul className='blog__left-nav-wrap'>
                <li
                  onClick={() => {
                    setCategory('all')
                  }}
                  className={`${
                    category === 'all'
                      ? 'blog__left-nav-list active'
                      : 'blog__left-nav-list'
                  }`}
                >
                  All Categories
                </li>
                <li
                  onClick={() => {
                    setCategory('undergraduate')
                  }}
                  className={`${
                    category === 'undergraduate'
                      ? 'blog__left-nav-list active'
                      : 'blog__left-nav-list'
                  }`}
                >
                  Undergraduate
                </li>
                <li
                  onClick={() => {
                    setCategory('postgraduate')
                  }}
                  className={`${
                    category === 'postgraduate'
                      ? 'blog__left-nav-list active'
                      : 'blog__left-nav-list'
                  }`}
                >
                  Postgraduate
                </li>
                <li
                  onClick={() => {
                    setCategory('diploma')
                  }}
                  className={`${
                    category === 'diploma'
                      ? 'blog__left-nav-list active'
                      : 'blog__left-nav-list'
                  }`}
                >
                  Diploma
                </li>
              </ul>
            </div>
            <div className='blog__left-body'>
              <div className='blog__left-body-wrapper'>
                {category === 'all' && (
                  <>
                    {HomeData.map((blog) => (
                      <div key={blog.id} className='blog__left-blog'>
                        <div className='blog__left-blog-image'>
                          <Link to='/newsdet'>
                            <img src={blog.Image} alt={blog.Title} />
                          </Link>
                        </div>
                        <div>
                          <Link to='/newsdet'>
                            <h2>{blog.Title}</h2>
                          </Link>
                          <p>{blog.Summary}</p>
                          <h3>
                            By {blog.Author} |{' '}
                            <span>
                              <Link to='/newsdet'>{blog.Comments} comments</Link>
                            </span>{' '}
                            | {blog.Date}
                          </h3>
                        </div>
                      </div>
                    ))}
                    <div className='blog-view'>
                      <Link to='/latestnews'>
                        <button>View More</button>
                      </Link>
                    </div>
                  </>
                )}
                {category === 'undergraduate' && (
                  <>
                    {HomeData.map((blog) => (
                      <div key={blog.id} className='blog__left-blog'>
                        <div className='blog__left-blog-image'>
                          <Link to='/newsdet'>
                            <img src={blog.Image} alt={blog.Title} />
                          </Link>
                        </div>
                        <div>
                          <Link to='/newsdet'>
                            <h2>{blog.Title}</h2>
                          </Link>
                          <p>{blog.Summary}</p>
                          <h3>
                            By {blog.Author} |{' '}
                            <span>
                              <Link to='/newsdet'>{blog.Comments} comments</Link>
                            </span>{' '}
                            | {blog.Date}
                          </h3>
                        </div>
                      </div>
                    ))}
                    <div className='blog-view'>
                      <Link to='/latestnews'>
                        <button>View More</button>
                      </Link>
                    </div>
                  </>
                )}
                {category === 'postgraduate' && (
                  <>
                    {HomeData.map((blog) => (
                      <div key={blog.id} className='blog__left-blog'>
                        <div className='blog__left-blog-image'>
                          <Link to='/newsdet'>
                            <img src={blog.Image} alt={blog.Title} />
                          </Link>
                        </div>
                        <div>
                          <Link to='/newsdet'>
                            <h2>{blog.Title}</h2>
                          </Link>
                          <p>{blog.Summary}</p>
                          <h3>
                            By {blog.Author} |{' '}
                            <span>
                              <Link to='/newsdet'>{blog.Comments} comments</Link>
                            </span>{' '}
                            | {blog.Date}
                          </h3>
                        </div>
                      </div>
                    ))}
                    <div className='blog-view'>
                      <Link to='/latestnews'>
                        <button>View More</button>
                      </Link>
                    </div>
                  </>
                )}
                {category === 'diploma' && (
                  <>
                    {HomeData.map((blog) => (
                      <div key={blog.id} className='blog__left-blog'>
                        <div className='blog__left-blog-image'>
                          <Link to='/newsdet'>
                            <img src={blog.Image} alt={blog.Title} />
                          </Link>
                        </div>
                        <div>
                          <Link to='/newsdet'>
                            <h2>{blog.Title}</h2>
                          </Link>
                          <p>{blog.Summary}</p>
                          <h3>
                            By {blog.Author} |{' '}
                            <span>
                              <Link to='/newsdet'>{blog.Comments} comments</Link>
                            </span>{' '}
                            | {blog.Date}
                          </h3>
                        </div>
                      </div>
                    ))}
                    <div className='blog-view'>
                      <Link to='/latestnews'>
                        <button>View More</button>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='blog__right'>
          <div className='blog__right-wrap'>
            <div className='blog__right-head'>
              <h2>Trending News</h2>
              <img src={Icon} alt='' />
            </div>
            <div className='blog__right-news-wrapper'>
              {TrendingData.map((news) => (
                <div key={news.id} className='blog__right-news'>
                  <Link to='/newsdet'>
                    <h2>{news.Title}</h2>
                  </Link>
                  <p>{news.Summary}</p>
                  <h4>{news.Date}</h4>
                </div>
              ))}
            </div>
            <div className='blog-view'>
              <Link to='/latestnews'>
                <button>View More</button>
              </Link>
            </div>
          </div>
        </div> */}
      </section>
      {/* <section className='schorlarship'>
        <div className='schorlarship-head'>
          <h1>Scholarship Update</h1>
          <div className='line-wrap'>
            {' '}
            <div className='line'></div>
          </div>
        </div>
        <div className='schorlarship-wrapper'>
          {SchorlashipData.map((news) => (
            <div key={news.id} className='schorlarship-card'>
              <div className='schorlarship-card-img'>
                <Link to='/newsdet'>
                  <img src={news.Image} alt='' />
                </Link>
              </div>
              <div className='schorlarship-card-text'>
                <Link to='/newsdet'>
                  <h2>{news.Title}</h2>
                </Link>
                <p>{news.Summary}</p>
                <h4>{news.Date}</h4>
              </div>
            </div>
          ))}
        </div>
        <div className='blog-view'>
          <Link to='/latestnews'>
            <button>View More</button>
          </Link>
        </div>
      </section> */}
    </>
  )
}

export default Home
